import React from 'react';
// import 'react-phone-number-input/style.css';
// import PhoneInput, { Country } from 'react-phone-number-input';
import PhoneInput, { Country, Value } from 'react-phone-number-input/input';

type PhoneNumberInputProps = {
  className?: string;
  id?: string;
  name: string;
  disabled?: boolean;
  error?: boolean;
  onBlur?: () => void;
  placeholder?: string;
  value: string;
  onChange: (value: Value) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

function PhoneNumberInput({
  className,
  name,
  id,
  disabled,
  error,
  onBlur,
  placeholder,
  value,
  onChange,
  onKeyPress,
}: PhoneNumberInputProps) {
  return (
    <PhoneInput
      id={id}
      country="US"
      className={`${className} ${error ? 'border-river-red' : ''}`}
      onBlur={onBlur}
      name={name}
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onKeyPress={onKeyPress}
    />
  );
}

PhoneNumberInput.defaultProps = {
  className: '',
  id: undefined,
  placeholder: '',
  disabled: false,
  error: false,
  onBlur: undefined,
  onKeyPress: undefined,
};

export default PhoneNumberInput;
