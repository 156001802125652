/* eslint-disable @typescript-eslint/no-use-before-define */
// https://lokeshdhakar.com/projects/color-thief

import ColorThief from 'colorthief';

const getColorFromImage = (imgUrl: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = imgUrl;
    image.crossOrigin = 'Anonymous';

    if (!imgUrl) {
      // Error but we will resolve and not reject with a positive response
      resolve('');
    }

    image.onload = function () {
      const colorThief = new ColorThief();
      const color = colorThief.getColor(this); // To get palette getPalette(this, number of colors)
      if (color && Array.isArray(color) && color.length > 1) {
        resolve(rgbToHex(color[0], color[1], color[2]));
      } else {
        resolve('');
      }
    };

    image.onerror = () => {
      // Error but we will resolve and not reject with a positive response
      resolve('');
    };
  });
};

const rgbToHex = (red: number, green: number, blue: number): string =>
  `#${[red, green, blue]
    .map(x => {
      const hex = x.toString(16);
      return hex.length === 1 ? `0${hex}` : hex;
    })
    .join('')}`;

export default getColorFromImage;
