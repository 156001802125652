import axios, { Method, AxiosRequestTransformer, AxiosResponseTransformer } from 'axios';
import toSnakeCase from 'snakecase-keys';
import toCamelCase from 'camelcase-keys';
import * as queryString from 'querystring';
import getCookie from './getCookie';
import { AuthToken } from '../context/authContext';
import env from './env';
import isValidURL from './isValidURL';

const objectToQueryString = (obj: Record<string, unknown>) => {
  const data = obj && toSnakeCase(obj, { deep: true });
  return queryString.stringify(data);
};

export const transformRequest = [
  (data: Record<string, unknown>) => {
    return data && toSnakeCase(data, { deep: true });
  },
  ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
];

export const transformResponse = [
  ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
  (data: Record<string, unknown>) => {
    return data && toCamelCase(data, { deep: true });
  },
];

const defaultRequests = {
  baseURL: env('RIVER_API_URL'),
  headers: () => {
    let AUTH_TOKEN = null;

    if (getCookie('_river_tokid')) {
      const authToken = JSON.parse(getCookie('_river_tokid') || '{}') as AuthToken;
      AUTH_TOKEN = authToken.token;
    }

    return {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...(AUTH_TOKEN ? { Authorization: `Bearer ${AUTH_TOKEN}` } : {}),
    };
  },
  transformRequest,
  transformResponse,
};

export const formatErrors = (error: any) => {
  let phoneNumber = '';
  let userId = '';

  const authTokenCookie = getCookie('_river_tokid');

  if (authTokenCookie) {
    phoneNumber = JSON.parse(authTokenCookie)?.phoneNumber || '';
    userId = JSON.parse(authTokenCookie)?.userId || '';
  }

  const responseError = error?.response || error;

  const {
    config: configData,
    data: serverData,
    status: serverStatus,
  } = (responseError || {}) as any;

  const config = {
    apiURL: configData?.url || '',
    method: configData?.method || '',
    data: configData?.data || '',
    phoneNumber,
    userId,
  };

  const status = serverStatus || responseError?.code || 'FRONTEND ERROR';

  let message =
    serverData?.error ||
    responseError?.message ||
    'Sorry! Something unusual happened at our end. Please contact our support team!';

  if (status >= 400 && status < 500) {
    message = `Sorry! Looks like there's issue with your inputs${
      serverData?.error ? ` (${serverData?.error}).` : '.'
    }`;

    if (serverData === 'Conflict') {
      message = serverData;
    }
  }

  if (status >= 500) {
    message = `Sorry! Something unusual happened at our end${
      serverData?.error ? ` (${serverData?.error}).` : '.'
    } Please contact our support team!`;
  }

  return { config, status, message };
};

export default function request(defaultConfigs = {}) {
  const defaults = { ...defaultRequests, ...defaultConfigs };
  const api = (method: Method, url: string, data: any) => {
    return new Promise((resolve, reject) => {
      axios({
        url: !isValidURL(url) ? `${defaults.baseURL}${url}` : url,
        method,
        headers: defaults.headers(),
        params: method === 'get' ? data : undefined,
        data: method !== 'get' ? data : undefined,
        paramsSerializer: {
          serialize: objectToQueryString,
        },
        transformRequest: defaults.transformRequest,
        transformResponse: defaults.transformResponse,
      }).then(
        response => {
          return resolve(response?.data?.data);
        },
        error => {
          reject(error);
        },
      );
    });
  };

  return {
    get: (url: string, data?: any) => api('get', url, data),
    post: (url: string, data?: any) => api('post', url, data),
    patch: (url: string, data?: any) => api('patch', url, data),
    put: (url: string, data?: any) => api('put', url, data),
    delete: (url: string, data?: any) => api('delete', url, data),
  };
}
