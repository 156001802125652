import React from 'react';
import { BrowserRouter, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import routes, { paths } from './routes';
import Dashboard from './pages/Dashboard/Dashboard';
import { AuthProvider, syncAuthUser, useAuth } from './context/authContext';
import getCookie from './utils/getCookie';
import NotFound from './pages/NotFound/NotFound';

function Redirect({ children, path }: { children: JSX.Element; path: string }) {
  const token = getCookie('_river_tokid');

  if (token && ![paths.DOCTOR_LISTINGS_PATH].includes(path)) {
    return <Navigate to={paths.HOME_URL_PATH} replace />;
  }

  return children;
}

function RequireAuth({ children }: { children: JSX.Element }) {
  const [{ user, status }, dispatch] = useAuth();
  const token = getCookie('_river_tokid');
  const location = useLocation();

  React.useEffect(() => {
    if (token && (!user || !user?.id)) {
      syncAuthUser(dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  if (!token) {
    return <Navigate to={paths.LOGIN_URL_PATH} state={{ from: location }} replace />;
  }

  if (status === 'LOADING' || !user || !user?.id) {
    return <div>Please wait...</div>;
  }

  return children;
}

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          {routes
            .filter(route => !route.private)
            .map(route => {
              const Component = route.component;

              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    <React.Suspense fallback={<div>Please wait...</div>}>
                      <Redirect path={route.path}>
                        <Component />
                      </Redirect>
                    </React.Suspense>
                  }
                />
              );
            })}
          {routes
            .filter(route => route.private)
            .map(route => {
              const Component = route.component;
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    <React.Suspense fallback={<div>Please wait...</div>}>
                      <RequireAuth>
                        <Dashboard>
                          <Component />
                        </Dashboard>
                      </RequireAuth>
                    </React.Suspense>
                  }
                />
              );
            })}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
