import * as Cookies from 'js-cookie';
import * as CryptoJS from 'crypto-js';
import env from './env';

const passPhrase = env('RIVER_PASSPHRASE') || '';

const getCookie = (key: string) => {
  try {
    const decipheredValue = CryptoJS.AES.decrypt(Cookies.get(key) || '', passPhrase);
    return decipheredValue.toString(CryptoJS.enc.Utf8);
  } catch {
    return null;
  }
};

export default getCookie;
